import React from "react";
import { Helmet } from "react-helmet";
import Container from "../components/container";
import Head from "../components/head";

export default function About() {
  return (
    <>
      <Head />
      <Container>
        <Helmet>
          <script>
            {`
              (function(d) {
                var config = {
                  kitId: 'mfv0wvg',
                  scriptTimeout: 3000,
                  async: true
                },
                h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
              })(document);
            `}
          </script>
        </Helmet>
        <header className="py-4 max-w-xl mx-auto px-4">
          <h1 className="text-xl">tape.fan!</h1>
        </header>
        <div className="py-10 md:py-40 px-8 md:text-center bg-gray-100">
          <h2 className="text-2xl md:text-5xl font-bold md:pb-10 pb-4">
            Let's have fun!
          </h2>
          <p className="md:text-xl leading-loose">
            カセットテープ、どんなイメージですか？
            <br />
            音楽を聴く？音楽を録音する？
            <br />
            好きなカセットテープを作る？
            <br />
            様々な楽しみ方があるカセットテープ。
            <br />
            また楽しんでみませんか？
          </p>
          <a
            href="https://cassette.tape.fan"
            className="inline-block text-center bg-blue-600 text-white md:px-8 py-2 px-4 w-full md:w-auto md:py-4 font-bold md:text-lg rounded-full no-underline	md:mt-10 mt-4"
          >
            コレクションサイトへ
          </a>
        </div>
        {/* <div className="border-indigo-300 border-2 p-5">
        <h2 className="mb-5 text-blue-600">カセットテープ コレクションサイト オープンしました！</h2>
        <a href="https://cassette.tape.fan/"><img src="https://cassette.tape.fan/tapes//ogp-1.png" alt=""/></a>
      </div> */}
        <div className="max-w-xl mx-auto px-4 pb-20">
          <h2 className="md:mt-20 mt-5 mb-5">Profile</h2>
          <h3>
            若月 啓聡
            <small className="block text-blue-200 text-sm">
              カセットテープコレクター
            </small>
          </h3>
          <p className="mt-5">
            カメラ店で未開封のカセットテープが販売されておりそれを購入したことがきっかけでカセットテープコレクターとなる。
            <br />
            開封済み約10000本・未開封約8000本の合計約1万本以上のカセットテープを所有している。
            <br />
            好きなカセットテープは、初代 MA-R
            あの重さと見た目のかっこよさは忘れられません。
          </p>
          <h2 className="md:mt-20 mt-5 mb-5">出演履歴</h2>
          <h3 className="mt-10 mb-5">Web</h3>
          <ul className="list-disc list-outside">
            <li>
              <a href="https://muuseo.com/square/articles/80">
                1000本以上のカセットテープを集めてきた若月さんの、時代の流れを巻き戻すコレクションの数々。
                | MUUSEO SQUARE
              </a>
            </li>
          </ul>
          <h3 className="mt-10 mb-5">テレビ</h3>
          <ul className="list-disc list-outside">
            <li>
              チャージ730！　2016年2月5日（金）07:30～08:15　（テレビ東京 ）
            </li>
            <li>ひるまえほっと 2016年11月08日（火） （NHK総合）</li>
            <li>
              スーパーJチャンネル　2016年12月24日（土）16:30～18:00　（テレビ朝日）
            </li>
            <li>
              所さんの学校では教えてくれないそこんトコロ！　
              2017年9月8日（金）20:54～21:54　（テレビ東京）
            </li>
            <li>ニュース シブ5時　2021年12月7日(火) 　（NHK総合）</li>
            <li>所JAPAN　2021年12月14日火　（フジテレビ）</li>
            <li>ニュース シブ5時　2021年12月7日(火) 　（NHK総合）</li>
            <li>ひるおび！　2022/01/21(金)　（TBSテレビ）</li>
          </ul>
          <h3 className="mt-10 mb-5">雑誌・本</h3>
          <ul className="list-disc list-outside">
            <li>
              カセットテープ時代 (CDジャーナルムック) <br />
              カセットテープ提供
            </li>
            <li>
              月刊リベラルタイム 10月号 2016年09月03日発売
              <br />
              カセットテープコレクター インタビュー
            </li>
            <li>
              Stereo（ステレオ）2019年11月号 カセットテープコレクター
              <br />
              インタビュー
            </li>
            <li>
              ７０年～８０年代のカセットテープ・カルチャーを振り返る　シティ・ポップとラジカセ
              <br />
              インタビュー・カセットテープ提供
            </li>
            <li>
              TDKカセットテープ・マニアックス (双葉社スーパームック)
              <br />
              カセットテープ提供
            </li>
            <li>
              マクセル・カセットテープ・マニアックス (双葉社スーパームック)
              <br />
              カセットテープ提供
            </li>
          </ul>

          {/* <h2 className="mt-10 mb-5">所有カセットデッキ</h2>
        <ul className="list-disc">
          <li>NAKAMICHI / DRAGON</li>
          <li>A&D / GX-Z9000</li>
          <li>A&D / GX-Z7100EV</li>
          <li>SONY / TC-RX55</li>
          <li>VICTOR / TD-V711</li>
          <li>VICTOR / KD-31</li>
          <li>VICTOR / KD-A55</li>
        </ul> */}

          <h2 className="mt-10 mb-5">お問い合わせ</h2>
          <p>
            取材や質問などございましたら、 若月 info@puzzel.jp
            までご連絡くださいませ。
          </p>
        </div>
        <footer className="bg-gray-100 py-20 text-center">
          <p className="font-xs text-gray-500">
            © 2019 tape.fan | produced by{" "}
            <a href="https://puzzel.jp/" target="blank">
              puzzel.jp
            </a>
            <br />
            <a href="https://cassette.tape.fan/privacy-policy">
              Privacy Policy
            </a>
          </p>
        </footer>
      </Container>
    </>
  );
}
